import { notNull } from "@warrenio/utils/notNull";
import sleep from "@warrenio/utils/promise/sleep";
import { getDefaultStore } from "jotai/vanilla";
import invariant from "tiny-invariant";
import { getBodyClasses } from "../../../styles/bodyClasses.ts";
import { currentColorSchemeAtom } from "../../main/ColorSchemeSwitcher.store.ts";
import { getFullUrl } from "../../main/urls.ts";
import { colorSchemeAttribute } from "../../theme/shared.ts";
import { themeCssHtmlAtom } from "../../theme/themeCssHtml.ts";
import popupCss from "./popup.css?inline";

const rootId = "popup-root";
const textId = "popup-text";

function makeHtml() {
    const store = getDefaultStore();

    const styleUrls: string[] = [];
    const styleTags = styleUrls.map((url) => `<link rel="stylesheet" href="${url}">`).join("\n");

    const themeCss = store.get(themeCssHtmlAtom);

    return `
<!DOCTYPE html>
<html>
<head>
    <base href="${getFullUrl("/")}">
    <title>Popup</title>
    ${styleTags}
    ${themeCss}
    <style>${popupCss}</style>
</head>
<body class="${getBodyClasses()}" ${colorSchemeAttribute}="${store.get(currentColorSchemeAtom)}">
    <div id="${rootId}">
        <div class="Spinner"><div class="Content"></div></div>
        <div id="${textId}">Loading...</div>
    </div>
</body>
</html>
`;
}

export interface SimpleWindow {
    document: Document;
    closed: boolean;
    close(): void;
}

export class PaymentPopup {
    private hasRedirected = false;

    constructor(private window: SimpleWindow) {
        console.debug("Constructing popup", window);

        // NB: Can not use `Blob` for content since it will not have the same origin
        window.document.documentElement.innerHTML = makeHtml();
    }

    /** @param isTrusted - Whether the currently handled event is trusted (eg. generated by a user action) */
    static open(isTrusted: boolean): PaymentPopup | undefined {
        // NB: If the event is generated by eg. react-testing-library, it will not be trusted and a real popup can not be opened
        // (so force attaching to the mock popup)
        if (import.meta.env.DEV && !isTrusted) {
            return attachMockPopup();
        }

        const w = window.open("", "_blank");
        if (!w) {
            console.warn("Failed to open popup");
            return undefined;
        }
        return new PaymentPopup(w);
    }

    handleRedirect(url: string) {
        console.debug("Redirecting popup to", url);

        // NB: Replace the content so that the user can not go back to the previous (empty about:blank) page
        this.window.document.location.replace(url);

        this.hasRedirected = true;
    }

    private hasReturned(): boolean {
        try {
            const location = this.window.document.location;
            if (location.href === "about:blank") {
                console.debug("Location is about:blank, waiting for redirect to load before returning");
                return false;
            }

            // If `location` is accessible, it means we are on the original origin, therefore assume the redirect is done
            console.debug("Popup location after redirect: %o", location.href);
            return true;
        } catch (e) {
            console.debug("Location inaccessible, waiting for redirect return: %s", String(e));
            return false;
        }
    }

    /** Wait for the popup to return from the redirect */
    async waitReturnFromRedirect(signal: AbortSignal) {
        invariant(this.hasRedirected, "Popup must have redirected before waiting for redirect");

        for (;;) {
            await sleep(1000, signal);

            if (this.hasReturned()) {
                return;
            }
        }
    }

    dispose() {
        if (!this.hasRedirected) {
            console.debug("Disposing popup");
            this.close();
        }
        // TODO: Can also auto-close when we have returned from the redirect
    }

    close() {
        console.debug("Closing popup");
        this.window.close();
    }

    get closed() {
        return this.window.closed;
    }

    setText(text: string) {
        try {
            const root = this.window.document.getElementById(textId)!;
            root.innerText = text;
        } catch (e) {
            console.error("Error in setText", e);
        }
    }

    toString() {
        try {
            const location = this.window.document.location;
            return `Popup(${location?.toString()}) closed=${this.closed}`;
        } catch (e) {
            console.error("Error in toString", e);
            return "Popup(unknown)";
        }
    }
}

//#region Mock popup

export const mockPopupId = "mock-popup-frame";

function attachMockPopup(): PaymentPopup | undefined {
    const iframe = document.getElementById(mockPopupId) as HTMLIFrameElement | null;
    if (!iframe) {
        console.warn("Mock popup frame not found, creating it");
        return undefined;
    }
    const iframeDocument = notNull(iframe.contentDocument);

    let closed = false;

    return new PaymentPopup({
        document: iframeDocument,
        get closed() {
            return closed;
        },
        close() {
            console.debug("Mock close");
            closed = true;
            iframe.srcdoc = '<html><body>❎ [<span data-testid="popup-closed">Popup closed</span>]</body></html>';
        },
    });
}

//#endregion
