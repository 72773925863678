import type { BillingAccountCard } from "@warrenio/api-spec/spec.oats.gen";
import { CurrencyBalance, OtherCurrencyBalance } from "../../components/l10n/Currency.tsx";
import { parseCardAdditionalData } from "./billingCardsQuery.ts";
import type { EBillingAccount } from "./billingLogic.tsx";
import type { MethodBase } from "./PaymentMethod.tsx";
import { getCalculatedFees, getCurrencyConversion } from "./topup/topUpUtils.ts";

export function ConversionMessage({
    account,
    amount,
    fees,
    card,
}: {
    account: EBillingAccount;
    amount: number;
    fees?: MethodBase["fee"];
    card?: BillingAccountCard;
}) {
    const { total } = getCalculatedFees(fees, amount, account.account.vat_percentage);
    // TODO: Unify VAT and currency conversion fee calculations
    const conversion = getCurrencyConversion(
        account,
        fees?.charge_currency_conversion,
        (card && parseCardAdditionalData(card)?.processor_data?.country) || undefined,
    );
    const convertedAmount = conversion ? total * conversion.exchange_rate : undefined;

    if (!conversion || convertedAmount === undefined) {
        return undefined;
    }

    return (
        <div className="color-muted m-0 p-0 pl-4">
            Your actual charge will be{" "}
            <OtherCurrencyBalance currency={conversion.to_currency} value={convertedAmount} />, with exchange rate of{" "}
            <CurrencyBalance value={1} /> ={" "}
            <OtherCurrencyBalance currency={conversion.to_currency} value={conversion.exchange_rate} />. The final
            exchange rate and charge amount will be determined at the moment of actual payment.
        </div>
    );
}
